@value colorAccent from '../../styles/colors.module.css';

.socialLinks {
  position: relative;
  display: flex;
  text-align: center;
  margin: 0 auto 12px;
}

@media (min-width: 760px) {
  .socialLinks {
    position: absolute;
    display: block;
    margin: 0;
    top: 14px;
    right: -40px;
    background-color: #fffc;
    padding: 4px;
    border-radius: 8px;
  }
}

@media (min-width: 1000px) {
  .socialLinks {
    right: -48px;
  }
}

.socialLink {
  width: 40px;
}

@media (min-width: 760px) {
  .socialLink {
    width: 30px;
  }
}

.socialLink + .socialLink {
  margin-left: 8px;
}

@media (min-width: 760px) {
  .socialLink + .socialLink {
    margin-top: 4px;
    margin-left: 0;
  }
}

.socialLink svg {
  transition: all 300ms ease-in-out;
}

.socialLink:hover svg {
  fill: colorAccent;
}
