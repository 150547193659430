.content {
  background-color: #ffffff;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  position: relative;
  border-radius: 0 0 8px 8px;
  position: relative;
  top: -48px;
  z-index: 1;
}

/* .content.defaultContent {
  position: relative;
} */

.content.homepageContent {
  position: static;
}

@media (min-width: 760px) {
  .content {
    margin-left: 18%;
    margin-right: 18%;
    top: 0;
  }
  .content.narrowContent {
    margin-left: 50%;
    margin-right: 5%;
    top: 0;
  }

  .content.blogContent {
    margin-left: 20%;
    margin-right: 20%;
    top: 0;
  }

  .content.wideContent {
    margin-left: 8%;
    margin-right: 8%;
    top: 0;
  }
}

.hero {
  min-height: 100vh;
}

.logo {
  padding: 0 24px;
  margin-left: -2px; /* This font is weird and the left side isn't flush :( */
}

@media (min-width: 760px) {
  .logo {
    margin: 0;
  }
}

.heading {
  position: relative;
}

.homepageContent .heading {
  position: absolute;
  top: 0;
  width: 96%;
}

@media (min-width: 760px) {
  .homepageContent .heading {
    position: relative;
    top: 0;
    width: 100%;
  }
}

.homepageContent .accountMenu {
  position: absolute;
  top: 8px;
  right: 0;
}
