@value colorRed from '../../styles/colors.module.css';

.cartButton {
  padding: 8px 12px;
  position: relative;
  width: 100%;
}

/* @media (min-width: 760px) {
  .cartButton {
    padding-bottom: 8px;
  }
} */

.cartLink {
  text-decoration: none;
  /* width: 100%; */
  display: flex;
  align-items: center;
}

/* @media (min-width: 760px) {
  .cartLink {
    width: auto;
  }
} */

.cartIcon {
  transform: scale(0.9);
  height: 34px;
}

@media (min-width: 760px) {
  .cartIcon {
    transform: scale(1.0);
  }
}

.cartCountBadgeContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 12px;
}

/* @media (min-width: 760px) {
  .cartCountBadgeContainer {
    top: -24px;
  }
} */

.cartCountBadge {
  border-radius: 24px;
  height: 20px;
  width: 20px;
  background-color: colorRed;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.cartEmptyLabel {
  color: #444;
  font-size: 14px;
}

.cartLabel {
  margin-left: 12px;
  font-size: 14px;
  color: #444;
  display: flex;
  gap: 4px;
}


/* @media (min-width: 760px) {
  .cartLabel {
    display: none;
  }
} */

.cartEmptyLabel {
  color: #777;
}
