@value colorRed, colorRedSaturated, colorRedAlt, colorBlueAlt, colorBlue, colorBlueText from '../../styles/colors.module.css';

.button {
  position: relative;
  display: block;
  background-color: colorRedSaturated;
  border: 1px solid colorRedSaturated;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms linear;
}

.button:hover {
  background-color: colorRedAlt;
  border: 1px solid colorRedAlt;
}

.buttonColorBlue {
  background-color: colorBlue;
  border: 1px solid colorBlueText;
}

.buttonColorBlue:hover {
  background-color: colorBlueAlt;
  border: 1px solid colorBlueText;
}

.button.isDisabled,
.button.isDisabled:hover {
  cursor: not-allowed;
  background-color: #fff;
  background: #f3f3f3;
  color: #999;
  border: 1px solid #ddd;
}

.button.isActivated {
  cursor: not-allowed;
  background-color: #fff;
  background: #fff;
  color: #999;
  border: 1px solid #ed1c24;
}

.backgroundSpinner {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.backgroundSpinnerInner {
  width: 30px;
  height: 30px;
  transform: scale(0.5);
}

.large {
  composes: button;
  font-size: 24px;
  border-radius: 6px;
  padding: 16px 24px;
  width: 100%;
}

@media (min-width: 760px) {
  .large {
    max-width: 420px;
    width: auto;
  }
}

.medium {
  composes: button;
  font-size: 18px;
  border-radius: 5px;
  padding: 12px 24px;
  max-width: 254px;
}

.small {
  composes: button;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
  max-width: 196px;
}

.tiny {
  composes: button;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 196px;
}

.smallText {
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
  max-width: 196px;
  background-color: transparent;
  color: #444;
  border-color: transparent;
  cursor: pointer;
}

/* .secondary {
  background-color: #666;
  border-color: #666;
  background: linear-gradient(75deg, #555, #777 );
} */

.secondary {
  background-color: #ffffff;
  border-color: colorRed;
  /* background: linear-gradient(75deg, #fff, #fafafa); */
  color: colorRed;
}

.button.secondary:hover {
  /* background: linear-gradient(75deg, #fff7f7, #fff9f9 ); */
  background-color: #fff9f9;
  border: 1px solid colorRed;
}

.secondary.buttonColorBlue {
  border-color: colorBlue;
  color: colorBlue;
}

.secondary.buttonColorBlue:hover {
  border-color: colorBlueAlt;
}

.buttonNoWrap {
  white-space: nowrap;
}
