@value colorRed from '../../styles/colors.module.css';

.logo {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 8px 0 12px;
  text-decoration: none;
  /* position: fixed; */
  /* top: 1em; */
  /* right: 1em; */
}

@media (min-width: 760px) {
  .logo {
    margin: 18px 8px 24px 0;
  }
}

.logoImage {
  width: 34px;
  margin-right: 8px;
}

@media (min-width: 760px) {
  .logoImage {
    width: 32px;
  }
}

.logoText {
  font-family: 'Josefin Sans', Verdana;
  font-size: 32px;
  text-transform: uppercase;
  color: colorRed;
  /* height: 32px; */
  line-height: 1;
  font-weight: bold;
  display: inline-block;
  position: relative;
  transition: color 1600ms linear;
}

@media (min-width: 760px) {
  .logoText {
    font-size: 40px;
  }
}

.logoTextGray {
  color: #777;
}

.betaLabel {
  composes: logoText;
  font-size: 12px;
  margin-left: 2px;
  top: 2px;
  vertical-align: top;
}

.tmLabel {
  composes: logoText;
  font-size: 14px;
  color: #ccc;
  position: absolute;
  top: 6px;
  left: 100%;
  font-family: helvetica;
  font-weight: normal;
}

@media (min-width: 760px) {
  .tmLabel {
    left: 99%;
    top: 8px;
  }
}

.textCard {
  margin-right: 2px;
}

/* .icon {

} */
