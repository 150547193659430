.backgroundPicture {
  position: absolute;
}

@media (min-width: 760px) {
  .backgroundPicture {
    position: absolute;
    z-index: -1;
  }
}

.backgroundPicture img {
  min-height: 100vh;
  min-width: 100vw;
  height: auto;
  /* width: 100vw; */
}



.background {
  background-image: url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360.jpg');
  background-image: image-set(
    url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360.jpg') 1x,
    url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360@2x.jpg') 2x
  );
  background-size: cover;
  background-position: left 90%;
  background-repeat: no-repeat;
  /* height: 200px; */
  width: 100vw;
  height: 80px;
  z-index: 1;
  display: flex;
}

@media
  (min-width: 760px) {
  .background {
    position: fixed;
    background-size: cover;
    /* background-position: 0 0; */
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: -1;
    background-position: 0 90%;
  }
}

@media
  (min-width: 760px) and
  (max-width: 999px) {
  .background {
    background-image: url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200.jpg');
    background-image: image-set(
      url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200.webp') 1x,
      url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200.jpg') 1x,
      url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.webp') 2x,
      url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.jpg') 2x
    );
  }
}

@media
  (min-width: 1000px) {
    .background {
      background-image: url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.jpg');
      background-image: image-set(
      url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.webp') 2x,
      url('https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.jpg') 2x
    );
    }
  }

.homepage {
  height: 58vh;
  position: absolute;
  z-index: 0;
}

@media (min-width: 760px) {
  .homepage {
    height: 100vh;
    position: fixed;
    z-index: -1;
  }
}

.homepage:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff6;
  background: linear-gradient(200deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.05) 70%);  /* , rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100% */
}

@media (min-width: 760px) {
  .homepage:after {
    display: none;
  }
}
