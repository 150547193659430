@value colorWhite, colorRed from '../../styles/colors.module.css';

.accountMenu {
  display: flex;
  position: absolute;
  right: 12px;
  height: 24px;
  top: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media (min-width: 760px) {
  .accountMenu {
    top: 15px;
    right: 24px;
    height: 24px;
  }
}

.accountMenuContent {
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  display: flex;
  /* flex-wrap: wrap; */
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  top: 24px;
  background-color: #fff;
  box-shadow: 0 0 0 #0003;
  width: 95vw;
  max-width: 320px;
  border-radius: 8px;
  flex-direction: column;
  z-index: 9;
  border: 1px solid transparent;
  border-top-width: 0;
  border-bottom-width: 0;
  padding: 0 8px;
  box-sizing: border-box;
  opacity: 0;
}

.accountMenuContentExpanded {
  composes: accountMenuContent;
  max-height: 320px;
  padding: 4px;
  box-shadow: 0px 2px 4px #0003;
  border: 1px solid #ddd;
  opacity: 1;
}

.backdropOn {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00000033;
  z-index: 5;
  transition: all linear 300ms;
}

.backdropOff {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  touch-action: none;
  background-color: transparent;
  transition: all linear 300ms;
}

/* @media (min-width: 760px) {
  .accountMenuContentExpanded,
  .accountMenuContent {
    max-height: 48px;
    position: relative;
    flex-direction: row;
    top: 0;
    background-color: transparent;
    box-shadow: none;
    width: auto;
  }
} */

.accountMenuButton {
  display: block;
  z-index: 7;
}

@media (min-width: 760px) {
  .accountMenuButton {
    /* display: none; */

  }
}

.actionItem {
  display: flex;
  align-items: center;
  padding: 4px 0px;
  min-height: 46px;
}

.actionItem:hover {
  background-color: colorRed;
}

.actionItem:hover a {
  color: colorWhite;
}

.actionItem:first-child {
  border-radius: 8px 8px 0 0;
}

.actionItem:last-child {
  border-radius: 0 0 8px 8px;
}

.actionItemNoIcon {
  composes: actionItem;
  /* padding-left: 43px; */
}

@media (min-width: 760px) {
  .actionItemNoIcon {
    padding-left: 0;
  }
}

.fullWidthButton {
  text-align: left; /* for logout button */
  width: 100%;
  max-width: none;
  text-decoration: none;
  font-size: 1.1rem;
  display: block;
  flex: 0 0 100%;
}
