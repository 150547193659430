.usOnlyWarning {
  width: 100vw;
  height: 48px;
  background-color: #ed4c47;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
